import Logo from "./coseke.png";
import AAR from "./aar.png";
import CISCO from "./cisco.png";
import DELL from "./dell.png";
import HPE from "./hpe.png";
import HUAWEI from "./huawei.png";
import NETAPP from "./netapp.png";
import PARLIAMENT from "./parliament.png";
import PAU from "./pau.png";
import VEEAM from "./veeam.png";
import ScannerOne from "./scanner.png";
import ScannerTwo from "./scanner1.png";
import ScannerThree from "./scanner2.png";
import ScannerFour from "./scanner3.png";
import ScannerFive from "./scanner4.png";
import ScannerSix from "./scanner5.png";
import ScannerSeven from "./scanner6.png";
import ISO from "./iso_img.png";

export const images = {
  Logo,
  ISO,
};

export const Scanners = {
  ScannerOne,
  ScannerTwo,
  ScannerThree,
  ScannerFour,
  ScannerFive,
  ScannerSix,
  ScannerSeven,
};

export const logos = {
  AAR,
  CISCO,
  DELL,
  HPE,
  HUAWEI,
  ISO,
  NETAPP,
  PARLIAMENT,
  PAU,
  VEEAM,
};
