import { createContext, useState } from "react";
import { IScanner } from "../../Interfaces/IScanner";

interface IScannerContext {
  rows: Array<IScanner>;
  setRows: React.Dispatch<React.SetStateAction<IScanner[]>>;
}

export const ScannerContext = createContext<IScannerContext>(
  {} as IScannerContext,
);

export const ScannerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [rows, setRows] = useState<Array<IScanner>>([] as Array<IScanner>);
  return (
    <ScannerContext.Provider value={{ rows, setRows }}>
      {children}
    </ScannerContext.Provider>
  );
};
