import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchScannersService } from "../../core/api/dashboardApi";
import { IScanner } from "../../Interfaces/IScanner";

export default function DataGridDemo({
  handleClose,
  open,
}: {
  handleOpen: () => void;
  handleClose: () => void;
  open: boolean;
}) {
  const [rows, setRows] = useState<IScanner[]>([]);

  const fetchAllScanners = async () => {
    const response = await fetchScannersService();
    console.log(response);
    setRows(response);
  };

  useEffect(() => {
    fetchAllScanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: "id", headerName: "No", width: 30 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {moment(params.row.date).format("LL")}
        </Typography>
      ),
    },
    {
      field: "upperCamera",
      headerName: "Upper Camera",
      flex: 1,
    },
    {
      field: "lowerCamera",
      headerName: "Lower Camera",
      flex: 1,
    },
    {
      field: "imagingGuides",
      headerName: "Imaging Guides",
      flex: 1,
    },
    {
      field: "mainControlBoard",
      headerName: "Main Control Board",
      flex: 1,
    },
    {
      field: "inputTray",
      headerName: "Input Tray",
      flex: 1,
    },
    {
      field: "outputTray",
      headerName: "Output Tray",
      flex: 1,
    },
    {
      field: "delete",
      headerName: "View",
      flex: 1,
      renderCell: () => (
        <Button size="small" variant="outlined">
          View
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.id!}
        disableRowSelectionOnClick
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontWeight: "bolder",
          },
        }}
      />
    </Box>
  );
}
