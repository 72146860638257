import { Box } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavigationBar from "./components/NavigationBar";
import AppRoutes from "./core/AppRoutes";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Box className="App">
      <ToastContainer />
      <Router>
        <NavigationBar />
        <AppRoutes />
      </Router>
    </Box>
  );
}
export default App;
