import React, { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { IBooking } from "../../Interfaces/IBooking";
import { getBookingsByEmail } from "../../core/api/bookingApi";
import { RemoveRedEyeRounded } from "@mui/icons-material";
import ViewBookingDialog from "../../components/ViewBookingDialog";

const MyScanners: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [open, setOpen] = useState<boolean>(true);
  const [rows, setRows] = useState<IBooking[]>([]);
  const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
  const [selectedBooking, setSelectedBooking] = useState<IBooking | null>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleViewDialogOpen = (booking: IBooking) => {
    setSelectedBooking(booking);
    setViewDialogOpen(true);
  };

  const handleFetchBookings = async () => {
    try {
      const bookings = await getBookingsByEmail(email);
      console.log(bookings);
      setRows(bookings);
      setOpen(false); // Close the dialog after fetching bookings
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  // Define columns based on IBooking interface
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "institution", headerName: "Institution", flex: 1 },
    { field: "noOfScanners", headerName: "No Of Scanners", flex: 1 },
    { field: "scannerModel", headerName: "Scanner Model", flex: 1 },
    {
      field: "dateApplied",
      headerName: "Date Applied",
      flex: 1,
      renderCell: (params: any) => {
        return <>{new Date(params.row.dateApplied).toDateString()}</>;
      },
    },
    {
      field: "dateOfAppointment",
      headerName: "Date of Appointment",
      flex: 2,
      renderCell: (params: any) => {
        return <>{new Date(params.row.dateOfAppointment).toLocaleString()}</>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton onClick={() => handleViewDialogOpen(params.row)}>
              <RemoveRedEyeRounded />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Enter Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email to fetch the scanner bookings.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleFetchBookings}>Fetch Bookings</Button>
        </DialogActions>
      </Dialog>
      <DataGrid rows={rows} columns={columns} pageSizeOptions={[5, 10, 25]} />
      <ViewBookingDialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        booking={selectedBooking}
      />
    </div>
  );
};

export default MyScanners;
