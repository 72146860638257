import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { getAllBookings } from "../../core/api/bookingApi";
import { IBooking } from "../../Interfaces/IBooking";
import { IScanner } from "../../Interfaces/IScanner";
import CreateScanner from "./CreateScanner";
import BasicModal from "./Modal";
import { ScannerContext } from "./ScannerContext";
import DataGridDemo from "./Table";

function Dashboard() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IScanner>({} as IScanner);
  const { rows, setRows } = React.useContext(ScannerContext);
  const [bookings, setBookings] = React.useState<IBooking[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeBooking = (booking: IBooking | null) => {
    setValues({ ...values, booking: booking ? booking : undefined });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllBookings();
        setBookings(res);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div>
      {
        <BasicModal handleClose={handleClose} open={open}>
          <CreateScanner
            handleChangeBooking={handleChangeBooking}
            rows={rows}
            setRows={setRows}
            handleClose={handleClose}
            handleChange={handleChange}
            values={values}
            bookings={bookings}
          />
        </BasicModal>
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 3,
        }}
      >
        <Typography variant="h5">Kodak Clinic</Typography>
        <Button onClick={handleOpen} size="large" variant="contained">
          Create Diagnosis Report
        </Button>
      </Box>
      <Grid container xs={12} p={3}>
        <DataGridDemo
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
        />
      </Grid>
    </div>
  );
}

export default Dashboard;
