import * as yup from "yup";

export const scannerSchema = yup.object({
  upperCamera: yup.string().required("Upper Camera is required"),
  lowerCamera: yup.string().required("Lower Camera is required"),
  imagingGuides: yup.string().required("Imaging Guides is required"),
  mainControlBoard: yup.string().required("Main Control Board is required"),
  eBoxUpper: yup.string().required("EBox Upper is required"),
  eBoxLower: yup.string().required("EBox Lower is required"),
  inputTray: yup.string().required("Input Tray is required"),
  outputTray: yup.string().required("Output Tray is required"),
  transportMotor: yup.string().required("Transport Motor is required"),
  transportRollers: yup.string().required("Transport Rollers are required"),
  pickupRollers: yup.string().required("Pickup Rollers are required"),
  feedModule: yup.string().required("Feed Module is required"),
  usbCable: yup.string().required("USB Cable is required"),
  powerAdapter: yup.string().required("Power Adapter is required"),
  status: yup.string().required("Status is required"),
  scannerSerialNumber: yup
    .string()
    .required("Scanner serial number is required!"),
  scannerName: yup.string().required("Scanner name is required!"),
  recommendNextServiceDate: yup
    .date()
    .required("Recommended Next Service Date is required!"),
});
