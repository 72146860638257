import axios from "axios";
import { baseURL } from "../baseUrl";

export const isAuthenticated = () => {
  return sessionStorage.getItem("user-token") !== null;
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${baseURL}/user/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
