import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import Slider from "react-slick";
import { animated, useTrail } from "react-spring";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Scanners } from "../../assets/images";
import { createBooking } from "../../core/api/bookingApi";
import { IBooking } from "../../Interfaces/IBooking";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

type FormFields = {
  name: string;
  email: string;
  institution: string;
  phoneNumber: string;
  noOfScanners: number;
  scannerModel: string;
  dateOfAppointment: string;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  institution: yup.string().required("Institution is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  noOfScanners: yup.number().required("Scanner type is required"),
  scannerModel: yup.string().required("Scanner model is required"),
  dateOfAppointment: yup.string().required("Date of appointment is required"),
});

const AnimatedTypography = animated(Typography);
const imageArray = Object.values(Scanners);

const Booking: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const textItems = [
    {
      text: "Kodak Alaris Free Service Clinic",
      variant: "h4",
      color: "#002c6d",
      fontWeight: "bold",
      top: 120,
      left: 100,
    },
    {
      text: "Service your scanner Starting from 26th Aug to 26th Sept!",
      variant: "h6",
      color: "#E81F27",
      top: 170,
      left: 100,
    },
  ];

  const scannerModels = [
    "i30 and i40 Scanner",
    "i600 Series Scanner",
    "i700 Series Scanner",
    "SCANMATE i1120 Scanner",
    "i1200 Series Scanner",
    "i1300 Series Scanner",
    "i1400 Series Scanner",
    "i1800 Series Scanner",
    "i2000 Series Scanner",
    "i3000 Series Scanner",
    "S2000 Series Scanner",
    "S3000 Series Scanner",
    "Scan Station 500 Scanner",
    "Scan Station 730EX Scanner",
    "Scan Station 730EX Plus Scanner",
    "i4000 Series Scanners",
    "i5000 Series Scanners",
    "SCANMATE i940 Scanner",
    "E1030 Scanner",
    "E1040 Scanner",
  ];

  const trail = useTrail(textItems.length, {
    from: { opacity: 0, transform: "translate3d(0,-10px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { tension: 300, friction: 10 },
  });

  const formik = useFormik<FormFields>({
    initialValues: {
      name: "",
      email: "",
      institution: "",
      phoneNumber: "",
      noOfScanners: 0,
      scannerModel: "",
      dateOfAppointment: "", // Ensure it's an empty string initially
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const res = await createBooking({
          ...values,
          dateOfAppointment: new Date(values.dateOfAppointment),
        } as IBooking);
        const dateOfAppointment = res.dateOfAppointment.toString();
        toast.success("Your booking has been successfully created!");
        setSnackbarMessage(
          `Your Appointment has been successfully scheduled for ${new Date(dateOfAppointment).toLocaleString()}`,
        );
        setOpenSnackbar(true);
        resetForm();
      } catch (e: any) {
        toast.error("Failed to create booking!");
      } finally {
        setLoading(false);
      }
    },
  });

  // Define array of form fields for easy rendering
  const formFields = [
    { id: "name", label: "Name", type: "text" },
    { id: "email", label: "Email", type: "email" },
    { id: "institution", label: "Institution", type: "text" },
    { id: "phoneNumber", label: "Phone Number", type: "tel" },
    { id: "noOfScanners", label: "No of Scanners", type: "number" },
    { id: "scannerModel", label: "Scanner Model", type: "select" },
    {
      id: "dateOfAppointment",
      label: "Date Of Appointment",
      type: "datetime-local",
    },
  ];

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  return (
    <Box>
      <Grid container sx={{ height: "70vh", marginTop: 5 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ height: "70vh", display: { xs: "none", md: "block" } }}
        >
          <Box textAlign="center">
            {trail.map((style, index) => (
              <AnimatedTypography
                key={index}
                sx={{
                  position: "absolute",
                  top: textItems[index].top,
                  left: textItems[index].left,
                  fontWeight: textItems[index].fontWeight,
                  color: textItems[index].color,
                }}
                variant={textItems[index].variant as any}
                style={style}
              >
                {textItems[index].text}
              </AnimatedTypography>
            ))}
          </Box>
          <Slider {...sliderSettings}>
            {imageArray.map((bgImage) => {
              return (
                <Box
                  sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    marginTop: "80px",
                    backgroundPosition: "center center",
                    height: "60vh",
                    order: { xs: 1, md: 2 },
                  }}
                ></Box>
              );
            })}
          </Slider>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: 10,
            order: { xs: 1, md: 2 },
          }}
        >
          <Grid container>
            <Grid item xs={12} mb={2}>
              <Box
                textAlign="center"
                sx={{ display: { sx: "block", md: "none" } }}
              >
                {trail.map((style, index) => (
                  <AnimatedTypography
                    key={index}
                    sx={{
                      position: "relative",
                      fontWeight: textItems[index].fontWeight,
                      color: textItems[index].color,
                    }}
                    variant={textItems[index].variant as any}
                    style={style}
                  >
                    {textItems[index].text}
                  </AnimatedTypography>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: { xs: "60px", md: "0px" } }}>
              <Paper
                elevation={3}
                sx={{ padding: 5, margin: { xs: -8 }, borderRadius: 5 }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    variant="h4"
                    color="#002c6d"
                    fontWeight="bold"
                    sx={{ fontSize: "16px" }}
                    mb={1}
                  >
                    Book your free scanner service or repair from here
                  </Typography>
                  <Grid container spacing={2}>
                    {formFields.map((field) => (
                      <Grid key={field.id} item xs={6}>
                        {field.type === "select" ? (
                          <FormControl
                            fullWidth
                            variant="standard"
                            margin="normal"
                          >
                            <InputLabel id={`${field.id}-label`}>
                              {field.label}
                            </InputLabel>
                            <Select
                              labelId={`${field.id}-label`}
                              id={field.id}
                              name={field.id}
                              value={
                                formik.values[field.id as keyof FormFields]
                              }
                              onChange={formik.handleChange}
                              error={
                                formik.touched[field.id as keyof FormFields] &&
                                Boolean(
                                  formik.errors[field.id as keyof FormFields],
                                )
                              }
                            >
                              {scannerModels.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                            {formik.touched[field.id as keyof FormFields] &&
                              formik.errors[field.id as keyof FormFields] && (
                                <FormHelperText error>
                                  {formik.errors[field.id as keyof FormFields]}
                                </FormHelperText>
                              )}
                          </FormControl>
                        ) : (
                          <TextField
                            fullWidth
                            id={field.id}
                            name={field.id}
                            label={field.label}
                            variant="standard"
                            margin="normal"
                            type={field.type}
                            value={formik.values[field.id as keyof FormFields]}
                            onChange={formik.handleChange}
                            error={
                              formik.touched[field.id as keyof FormFields] &&
                              Boolean(
                                formik.errors[field.id as keyof FormFields],
                              )
                            }
                            helperText={
                              formik.touched[field.id as keyof FormFields] &&
                              formik.errors[field.id as keyof FormFields]
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 10 }}
                  >
                    Book Now
                  </Button>
                </form>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Booking;
