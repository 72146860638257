import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { ChangeEvent, useState } from "react";
import { IBooking } from "../../Interfaces/IBooking";
import { IScanner } from "../../Interfaces/IScanner";
import { scannerSchema } from "../../schemas/scannerSchema";
import InputComponent from "./InputComponent";
import { createNewScannerService } from "../../core/api/dashboardApi";
import { toast } from "react-toastify";

const CreateScanner = ({
  handleChange,
  setRows,
  rows,
  values,
  handleClose,
  bookings,
  handleChangeBooking,
}: {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  values: IScanner;
  handleClose: () => void;
  setRows: (scanner: IScanner[]) => void;
  rows: IScanner[];
  handleChangeBooking: (booking: IBooking | null) => void;
  bookings: IBooking[];
}) => {
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const [filteredBookings, setFilteredBookings] = useState<IBooking[]>([]);
  const [selectedBooking, setSelectedBooking] = useState<IBooking | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Ensure unique email addresses
  const uniqueEmails = Array.from(
    new Set(bookings.map((booking) => booking.email)),
  );

  const handleEmailChange = (event: SelectChangeEvent<string>) => {
    const email = event.target.value;
    setSelectedEmail(email);
    const filtered = bookings.filter((booking) => booking.email === email);
    setFilteredBookings(filtered);
    if (filtered.length === 1) {
      handleChangeBooking(filtered[0]);
      setSelectedBooking(filtered[0]);
    } else {
      handleChangeBooking(null);
      setSelectedBooking(null);
    }
  };

  const handleSubmit = async (vals: IScanner) => {
    setLoading(true);
    const dateInputElement = document.getElementById(
      "recommendNextServiceDate",
    ) as HTMLInputElement;

    // Get the value from the input element
    let recommendNextServiceDate = dateInputElement?.value;

    let req = {
      ...vals,
      ...{
        ...values,
        recommendNextServiceDate: new Date(
          recommendNextServiceDate,
        ).toISOString(),
      },
    };
    try {
      if (vals) {
        const response = await createNewScannerService(req);
        setRows([...rows, response]);
        toast.success("Sucessfully submitted diagnosis!");
        handleClose();
      } else {
        toast.error("Booking cannot be null!");
      }
    } catch (e: any) {
      toast.error("Failed to create Diagnostic report!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectBooking = (booking: IBooking) => {
    handleChangeBooking(booking);
    setSelectedBooking(booking);
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={scannerSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ handleChange, values, errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
            {/* Left Side Content */}
            <Grid item xs={12} md={6} sx={{ maxHeight: 600, overflow: "auto" }}>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Booking Details
              </Typography>
              {/* Booking Details Section */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Email</InputLabel>
                <Select value={selectedEmail} onChange={handleEmailChange}>
                  {uniqueEmails.map((email) => (
                    <MenuItem key={email} value={email}>
                      {email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedBooking ? (
                <>
                  {/* Display selected booking details */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="name"
                        label="Name"
                        value={selectedBooking.name}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="phoneNumber"
                        label="Phone Number"
                        value={selectedBooking.phoneNumber}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="institution"
                        label="Institution"
                        value={selectedBooking.institution}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="noOfScanners"
                        label="No. Of Scanners"
                        value={selectedBooking.noOfScanners.toString()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="scannerModel"
                        label="Scanner Model"
                        value={selectedBooking.scannerModel}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputComponent
                        name="dateOfAppointment"
                        label="Date of Appointment"
                        value={new Date(
                          selectedBooking.dateOfAppointment.toString(),
                        ).toLocaleString()}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                filteredBookings.length > 1 && (
                  <Grid item xs={12}>
                    {filteredBookings.map((booking) => (
                      <Button
                        key={booking.id}
                        onClick={() => handleSelectBooking(booking)}
                        sx={{ display: "block", mb: 1 }}
                      >
                        Select {booking.name} (
                        {new Date(
                          booking.dateOfAppointment.toString(),
                        ).toLocaleString()}
                        )
                      </Button>
                    ))}
                  </Grid>
                )
              )}
              {/* Scanner Details Fields */}
              <Grid item xs={12} mt={2}>
                <Divider flexItem />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Field
                  name="scannerName"
                  label="Scanner Name"
                  as={InputComponent}
                  handleChange={handleChange}
                  value={values.scannerName}
                  error={Boolean(errors.scannerName)}
                  helperText={errors.scannerName}
                  touched={Boolean(touched.scannerName)}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <Field
                  name="scannerSerialNumber"
                  label="Scanner Serial Number"
                  as={InputComponent}
                  handleChange={handleChange}
                  value={values.scannerSerialNumber}
                  error={Boolean(errors.scannerSerialNumber)}
                  helperText={errors.scannerSerialNumber}
                  touched={Boolean(touched.scannerSerialNumber)}
                />
              </Grid>
            </Grid>

            {/* Right Side Content */}
            <Grid item xs={12} md={6} sx={{ maxHeight: 600, overflow: "auto" }}>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Provide Scanner Details
              </Typography>
              <Grid container spacing={3}>
                {[
                  { name: "upperCamera", label: "Upper Camera" },
                  { name: "lowerCamera", label: "Lower Camera" },
                  { name: "imagingGuides", label: "Imaging Guides" },
                  { name: "mainControlBoard", label: "Main Control Board" },
                  { name: "eBoxUpper", label: "EBox Upper" },
                  { name: "eBoxLower", label: "EBox Lower" },
                  { name: "inputTray", label: "Input Tray" },
                  { name: "outputTray", label: "Output Tray" },
                  { name: "transportMotor", label: "Transport Motor" },
                  { name: "transportRollers", label: "Transport Rollers" },
                  { name: "pickupRollers", label: "Pickup Rollers" },
                  { name: "feedModule", label: "Feed Module" },
                  { name: "usbCable", label: "USB Cable" },
                  { name: "powerAdapter", label: "Power Adapter" },
                  { name: "status", label: "Status" },
                  {
                    name: "recommendNextServiceDate",
                    label: "Recommended Next Service Date",
                    type: "datetime-local",
                  },
                ].map((field, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Field
                      name={field.name}
                      label={field.label}
                      as={InputComponent}
                      handleChange={handleChange}
                      value={values[field.name as keyof IScanner]}
                      error={Boolean(errors[field.name as keyof IScanner])}
                      helperText={errors[field.name as keyof IScanner]}
                      touched={Boolean(touched[field.name as keyof IScanner])}
                      type={field.type ? field.type : "text"}
                    />
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="outlined"
                    color="error"
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={loading} variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateScanner;
