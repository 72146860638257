import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { IBooking } from "../Interfaces/IBooking";
import { useState } from "react";

const ViewBookingDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  booking: IBooking | null;
}> = ({ open, onClose, booking }) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!booking) return null;

  const status =
    booking.scanners.length >= booking.noOfScanners ? "Completed" : "Pending";

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Booking Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Booking Information
            </Typography>
            <Typography>
              <strong>Name:</strong> {booking.name}
            </Typography>
            <Typography>
              <strong>Email:</strong> {booking.email}
            </Typography>
            <Typography>
              <strong>Phone Number:</strong> {booking.phoneNumber}
            </Typography>
            <Typography>
              <strong>Institution:</strong> {booking.institution}
            </Typography>
            <Typography>
              <strong>Number of Scanners:</strong> {booking.noOfScanners}
            </Typography>
            <Typography>
              <strong>Scanner Model:</strong> {booking.scannerModel}
            </Typography>
            <Typography>
              <strong>Date Applied:</strong>{" "}
              {new Date(booking.dateApplied).toDateString()}
            </Typography>
            <Typography>
              <strong>Date of Appointment:</strong>{" "}
              {new Date(booking.dateOfAppointment).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Status:</strong> {status}
            </Typography>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item xs={12} md={5}>
            {booking.scanners.length > 0 ? (
              <Box>
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  aria-label="scanner details tabs"
                >
                  {booking.scanners.map((scanner, index) => (
                    <Tab label={`${scanner.scannerName}`} key={index} />
                  ))}
                </Tabs>
                {booking.scanners.map((scanner, index) => (
                  <TabPanel value={tabIndex} index={index} key={index}>
                    <Typography variant="h6" gutterBottom>
                      Scanner Information
                    </Typography>
                    <Typography>
                      <strong>Serial Number:</strong> {scanner.scannerName}
                    </Typography>
                    <Typography>
                      <strong>Serial Number:</strong>{" "}
                      {scanner.scannerSerialNumber}
                    </Typography>
                    <Typography>
                      <strong>Upper Camera:</strong> {scanner.upperCamera}
                    </Typography>
                    <Typography>
                      <strong>Lower Camera:</strong> {scanner.lowerCamera}
                    </Typography>
                    <Typography>
                      <strong>Imaging Guides:</strong> {scanner.imagingGuides}
                    </Typography>
                    <Typography>
                      <strong>Main Control Board:</strong>{" "}
                      {scanner.mainControlBoard}
                    </Typography>
                    <Typography>
                      <strong>E-Box Upper:</strong> {scanner.eBoxUpper}
                    </Typography>
                    <Typography>
                      <strong>E-Box Lower:</strong> {scanner.eBoxLower}
                    </Typography>
                    <Typography>
                      <strong>Input Tray:</strong> {scanner.inputTray}
                    </Typography>
                    <Typography>
                      <strong>Output Tray:</strong> {scanner.outputTray}
                    </Typography>
                    <Typography>
                      <strong>Transport Motor:</strong> {scanner.transportMotor}
                    </Typography>
                    <Typography>
                      <strong>Transport Rollers:</strong>{" "}
                      {scanner.transportRollers}
                    </Typography>
                    <Typography>
                      <strong>Pickup Rollers:</strong> {scanner.pickupRollers}
                    </Typography>
                    <Typography>
                      <strong>Feed Module:</strong> {scanner.feedModule}
                    </Typography>
                    <Typography>
                      <strong>USB Cable:</strong> {scanner.usbCable}
                    </Typography>
                    <Typography>
                      <strong>Power Adapter:</strong> {scanner.powerAdapter}
                    </Typography>
                    <Typography>
                      <strong>Scanner Status:</strong> {scanner.status}
                    </Typography>
                    <Typography>
                      <strong>Date of Diagnosis:</strong>{" "}
                      {new Date(scanner.date).toLocaleString()}
                    </Typography>
                  </TabPanel>
                ))}
              </Box>
            ) : (
              <Typography>No Scanner information available.</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

export default ViewBookingDialog;
