import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ScannerContextProvider } from "./pages/Diagnosis/ScannerContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ScannerContextProvider>
      <App />
    </ScannerContextProvider>
  </React.StrictMode>,
);
