const HOME = "/";
const DASHBOARD = "/dashboard";
const LOGIN = "/login";
const MYSCANNERS = "/my-scanners";
const TICKETS = "/tickets";
const DIAGNOSIS = "/diagnosis";

export const ROUTES = {
  HOME,
  DASHBOARD,
  LOGIN,
  MYSCANNERS,
  TICKETS,
  DIAGNOSIS,
};
