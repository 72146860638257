import axios from "axios";

export const baseURL = "https://kodakclinicapi.cosekeservices.com/api/v1";
//export const baseURL = "http://localhost:7777/api/v1";

export const AxiosInstance = axios.create({
  baseURL,
});

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("user-token") || "",
      authorization = `Bearer ${token}`,
      contentType = "application/json";

    if (token) {
      config.headers["Authorization"] = authorization;
      config.headers["Content-Type"] = contentType;
    } else config.headers["Content-Type"] = contentType;
    return config;
  },
  (error) => Promise.reject(error),
);
