import { Route, Routes } from "react-router";
import Book from "../pages/Book";
import Diagnosis from "../pages/Diagnosis";
import Login from "../pages/Login";
import MyScanners from "../pages/MyAppointments";
import Tickets from "../pages/Tickets";
import { PrivateRoute } from "./PrivateRoute";
import { ROUTES } from "./routes";

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Book />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.MYSCANNERS} element={<MyScanners />} />
      <Route element={<PrivateRoute />}>
        <Route path={ROUTES.TICKETS} element={<Tickets />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path={ROUTES.DIAGNOSIS} element={<Diagnosis />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
