import { IScanner } from "../../Interfaces/IScanner";
import { AxiosInstance, baseURL } from "../baseUrl";

export const fetchScannersService = async (): Promise<IScanner[]> => {
  try {
    const response = await AxiosInstance.get(`${baseURL}/scanners`);
    return response.data;
  } catch (error) {
    console.log(error, "errors");
    return null as unknown as IScanner[];
  }
};

export const createNewScannerService = async (
  body: IScanner,
): Promise<IScanner> => {
  try {
    const response = await AxiosInstance.post(`${baseURL}/scanners`, body);
    return response.data;
  } catch (error) {
    console.log(error);
    return null as unknown as IScanner;
  }
};
