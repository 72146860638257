import axios from "axios";
import { baseURL } from "../baseUrl";
import { IBooking } from "../../Interfaces/IBooking";

export const createBooking = async (booking: IBooking): Promise<IBooking> => {
  try {
    const response = await axios.post(`${baseURL}/booking/`, booking);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getBookingsByEmail = async (
  email: string,
): Promise<IBooking[]> => {
  try {
    const response = await axios.get(`${baseURL}/booking/${email}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getBookingsById = async (id: number): Promise<IBooking[]> => {
  try {
    const response = await axios.get(`${baseURL}/booking/single/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getAllBookings = async () => {
  try {
    const response = await axios.get(`${baseURL}/booking/`);
    return response.data;
  } catch (e) {
    throw e;
  }
};
