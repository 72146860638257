import { TextField } from "@mui/material";
import React, { ChangeEvent } from "react";

interface IInputComponent {
  label?: string;
  value: string;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
}

const InputComponent = ({
  label,
  value,
  name,
  handleChange,
  type = "text",
  disabled = false,
}: IInputComponent) => {
  return (
    <TextField
      type={type}
      sx={{ width: "100%" }}
      size="small"
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleChange !== undefined ? handleChange(e) : null
      }
      id={name}
      value={value}
      name={name}
      label={label}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  );
};

export default InputComponent;
