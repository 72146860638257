import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router";
import { images } from "../assets/images";
import { isAuthenticated } from "../core/api/authApi";

const pages = [{ title: "My Appointments", link: "/my-scanners" }];
const adminPages = [
  { title: "Tickets", link: "/tickets" },
  { title: "Diagnosis", link: "/diagnosis" },
];
const settings = [
  { title: "Dashboard", link: "/" },
  { title: "Logout", link: null },
];

function NavigationBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };

  const handleCloseNavMenu = () => {
    setDrawerOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const handleClick = (link: string | null) => {
    if (link) {
      navigate(link);
    } else {
      sessionStorage.clear();
      navigate("/");
    }
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                component={"div"}
                onClick={() => navigate("/")}
                sx={{ flexGrow: 0.5, display: { xs: "none", md: "flex" } }}
              >
                <img
                  src={images.Logo}
                  alt="Logo"
                  style={{
                    height: 80,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                />
              </Box>
              <Box
                component={"div"}
                onClick={() => navigate("/")}
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              >
                <img src={images.ISO} alt="Logo" style={{ height: 60 }} />
              </Box>
              <Box
                sx={{
                  flexGrow: 10,
                  display: { xs: "none", md: "flex" },
                }}
              >
                {isAuthenticated()
                  ? adminPages.map((page) => (
                      <Button
                        key={page.link}
                        onClick={() => navigate(page.link)}
                        sx={{
                          my: 2,
                          color: "black",
                          display: "block",
                          boxShadow: "5px 5px 10px 1px #d3d3d3",
                          border: "1px solid gray",
                          mx: 1,
                          ":hover": {
                            border: "2px solid black",
                          },
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {page.title}
                      </Button>
                    ))
                  : pages.map((page) => (
                      <Button
                        key={page.link}
                        variant="outlined"
                        onClick={() => navigate(page.link)}
                        sx={{
                          my: 2,
                          color: "black",
                          display: "block",
                          boxShadow: "5px 5px 10px 1px #d3d3d3",
                          border: "1px solid gray",
                          ":hover": {
                            border: "2px solid black",
                          },
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {page.title}
                      </Button>
                    ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                {isAuthenticated() ? (
                  <Tooltip title="Open User Menu">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt="User Avatar"
                        src="/static/images/avatar.jpg"
                      />{" "}
                      {/* Replace with your user avatar */}
                    </IconButton>
                  </Tooltip>
                ) : null}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.title}
                      onClick={() => handleClick(setting.link)}
                    >
                      <Typography variant="body2">{setting.title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={handleCloseNavMenu}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleCloseNavMenu}
          onKeyDown={handleCloseNavMenu}
        >
          <Box
            component={"div"}
            onClick={() => navigate("/")}
            sx={{ p: 2, textAlign: "center" }}
          >
            <img src={images.Logo} alt="Logo" style={{ height: 60 }} />
          </Box>
          <List>
            {isAuthenticated()
              ? adminPages.map((page) => (
                  <ListItem key={page.link} onClick={() => navigate(page.link)}>
                    <ListItemText primary={page.title} />
                  </ListItem>
                ))
              : pages.map((page) => (
                  <ListItem key={page.link} onClick={() => navigate(page.link)}>
                    <ListItemText primary={page.title} />
                  </ListItem>
                ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default NavigationBar;
