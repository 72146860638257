import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { IBooking } from "../../Interfaces/IBooking";
import { getAllBookings } from "../../core/api/bookingApi";

function Tickets() {
  const [rows, setRows] = useState<IBooking[]>([]);

  const handleFetchBookings = async () => {
    try {
      const bookings = await getAllBookings();
      setRows(bookings);
      console.log("sdcsdc");
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  useEffect(() => {
    handleFetchBookings();
  }, []);

  // Define columns based on IBooking interface
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "institution", headerName: "Institution", flex: 1 },
    { field: "email", headerName: "Email", flex: 1  },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1  },
    { field: "noOfScanners", headerName: "No Of Scanners", flex: 1 },
    { field: "scannerModel", headerName: "Scanner Model", flex: 1 },
    {
      field: "dateApplied",
      headerName: "Date Applied",
      flex: 1,
      renderCell: (params: any) => {
        return <>{new Date(params.row.dateApplied).toDateString()}</>;
      },
    },
    {
      field: "dateOfAppointment",
      headerName: "Date of Appointment",
      flex: 2,
      renderCell: (params: any) => {
        return <>{new Date(params.row.dateOfAppointment).toLocaleString()}</>;
      },
    },
    { field: "options", headerName: "Options", flex: 1.5 },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} pageSizeOptions={[5, 10, 25]} />
    </div>
  );
}

export default Tickets;
